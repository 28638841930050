import React from "react";
import ConfigMap from "../config/components-map";

export default class PageBuilderService {
  public transformJSONIntoComponents(sectionId: string, sectionName: string, landingJson: any) {
    const components: any = [];

    if (!landingJson || !sectionName) return <></>;

    for (let key in landingJson) {
      /**
       * Filter custom fields that have all properties null because in WordPress there aren't a way
       * to avoid that a null property appears into the GraphQL query
       */
      if (landingJson[key]) {
        const propsNotNull = Object.values(landingJson[key]).filter(
          (value) => value && typeof value !== "object"
        );
        const hasAllPropsNull = propsNotNull.length === 1;

        if (!hasAllPropsNull) {
          components.push(
            this.generateComponent(sectionId, sectionName, key, landingJson[key])
          );
        }
      }
    }

    return components;
  }

  public generateComponent(
    sectionId: string,
    sectionName: string,
    componentName: string,
    props: any
  ) {
    const component: React.FC<any> = ConfigMap[sectionName][componentName];
    return React.createElement(component, { id: sectionId, ...props });
  }
}
