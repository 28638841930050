import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import LayoutV2 from "../components/layout-v2";
import PageBuilderService from "../services/page-builder-service";

const LandingPage = ({ data, pageContext }) => {
  const [seo, setSEO] = useState("");
  const [type, setType] = useState("");
  const [sections, setSections] = useState([]);
  const pageBuilderService = new PageBuilderService();

  useEffect(() => {
    const {
      landing: {
        seo,
        sections: { type, sections },
      },
    } = data;

    setSEO(seo);
    setType(type);
    setSections(sections);
  }, []);

  return (
    <LayoutV2
      id={data.landing.databaseId}
      hasNavbar={type !== "Squeeze"}
      hasFooter={type !== "Squeeze"}
      seo={seo}
    >
      <span datatype="landing_sections" data-post-id={data.landing.databaseId}>
        {sections.map(({ section }, index) => {
          if (!section) return <></>;

          const sec = pageBuilderService.transformJSONIntoComponents(
            section.databaseId,
            Object.keys(section)[2], // If add another field into WpLanding query, increment these indexes
            Object.values(section)[2]
          );

          return <span key={index}>{sec}</span>;
        })}
      </span>
    </LayoutV2>
  );
};

export const query = graphql`
  query landingQuery($id: String!) {
    landing: wpLanding(id: { eq: $id }) {
      ...Landing
    }
  }
`;

export default LandingPage;
